const semestersData = [
    //Semester 1
  {
    id: 1,
    name: "Semester 1",
    subjects: [
        //physics
      {
        subjectName: "Physics",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "S H M 1",
                documentUrl:
                  "https://drive.google.com/file/d/1fu_DCS8gL56LT6XO6uTHygL-opbdw-z7/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913016/CollegeNotesApp/sem1/physics/uy1dzmxe7eb32tsxgkng.gif",
                description: "Simple Harmonic Motion (SHM) is a fundamental concept in physics and describes the repetitive back-and-forth motion of an object around a stable equilibrium position.SHM has wide applications in physics, engineering, and various scientific disciplines. It serves as the foundation for understanding more complex oscillatory phenomena and waves.",
              },
            ],
          },

          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "WAVE MOTION",
                documentUrl:
                  "https://drive.google.com/file/d/1fxv5rUxC2whM1qvi3sATcChjofTnCVQw/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695912927/CollegeNotesApp/sem1/physics/fap1inleu3h8sfirawku.gif",
                description: "Wave motion is a fundamental concept in physics, encompassing the transmission of energy through oscillations in a medium or even through empty space. There are two primary categories of waves: mechanical waves and electromagnetic waves.",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Electrostatic",
                documentUrl:
                  "https://drive.google.com/file/d/1gjQ48e0-kpefzwfpqjTsr470AJWrTmmL/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913010/CollegeNotesApp/sem1/physics/unbgf6gfer9oeplvm59w.jpg",
                description: "Electrostatics in a vacuum refers to the study of electric charges and their interactions in a region devoid of matter or any material medium. In this context, vacuum signifies a space where there are no atoms or molecules to serve as conductors or insulator",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "Wave Optics",
                documentUrl:
                  "https://drive.google.com/file/d/1g9gzPpcw99guUCmsvY92b5QMNtc454gF/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695912822/CollegeNotesApp/sem1/physics/j4fdpepgaivi3tgryu3c.png",
                description: "Wave optics, also known as physical optics or wave theory of light, is a branch of optics that describes the behavior of light as waves. It provides a comprehensive framework for understanding various optical phenomena, such as diffraction, interference, polarization, and dispersion",
              },
            ],
          },
        
        ],
      },
        //Chemistry
      {
        subjectName: "Chemistry",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Thermo-Dynamics",
                documentUrl:
                  "https://drive.google.com/file/d/1iGWD1DTjl31Ilqit60D0tXFeK6M4nQLP/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913249/CollegeNotesApp/sem1/chemistry/rd2lqhdrv3cwbta6wja8.jpg",
                description: "Thermodynamics is a branch of physics and engineering that deals with the principles governing the behavior of energy and matter, particularly heat and work, within a system. It encompasses several fundamental concepts and laws that are essential for understanding and analyzing energy",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "SpectroScopy",
                documentUrl:
                  "https://drive.google.com/file/d/1iU25bi5G_CAchT3KSWaRcwQHo8D1n-My/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913284/CollegeNotesApp/sem1/chemistry/ruhdiwngamgffoezguh4.jpg",
                description: "spectroscopy is a versatile scientific technique that plays a pivotal role in characterizing matter and understanding its properties. It has widespread applications across scientific disciplines, making it a valuable tool for research, analysis, and exploration.",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Use of Gibbs Free Energy",
                documentUrl:
                  "https://drive.google.com/file/d/1iTn5xWl79qEjTUJ65gac3RjwnlblhtdB/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913364/CollegeNotesApp/sem1/chemistry/nz3rstrsnfbhbntbnqvd.jpg",
                description: "Gibbs free energy, denoted as ΔG, is a thermodynamic concept used to determine whether a chemical or physical process can occur spontaneously. If ΔG is negative, the process is spontaneous, indicating that energy is released",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "StereoChemistry",
                documentUrl:
                  "https://drive.google.com/file/d/1iWWoddhK5P4mZu0eRUt2eud75_x45UDe/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913321/CollegeNotesApp/sem1/chemistry/xybrdxgqwnwy7gavkpri.jpg",
                description: "You can provide the description of your notes",
              },
            ],
          },
          
        ],
      },
      //Biology
      {
        subjectName: "Biology",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Cell",
                documentUrl:
                  "https://drive.google.com/file/d/1hRtrZwL212jfoAVAI5jRpmAKNZkWBINJ/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913497/CollegeNotesApp/sem1/biology/c0dalm9vaabvjdxoxbbj.png",
                description: "A cell is the basic structural and functional unit of life in biology. It is enclosed by a membrane, contains genetic material, and performs essential processes such as metabolism, growth, and reproduction.",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Carbohydrates",
                documentUrl:
                  "https://drive.google.com/file/d/1mjtORNBfGPpAHSk2gRc0kGogYKDTFKIV/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913520/CollegeNotesApp/sem1/biology/ppwsho6k9rtdjgtjcycr.jpg",
                description: "Carbohydrates are organic compounds found in various foods like grains, fruits, and vegetables. They serve as a primary source of energy for the body. These molecules consist of carbon, hydrogen, and oxygen atoms ",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Chromosome",
                documentUrl:
                  "https://drive.google.com/file/d/1mqzwBFdnhbMl6l3OmbKni8gPjaN4DNny/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913592/CollegeNotesApp/sem1/biology/sdzqvhwghbesbkpjr1wr.svg",
                description: "Chromosomes are thread-like structures located within the nucleus of a cell. They carry genetic information in the form of DNA and are crucial for cell division and inheritance. Humans typically have 46 chromosomes (23 pairs), with one set inherited from each parent",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "PhotoSynthesis",
                documentUrl:
                  "https://drive.google.com/file/d/1hWexda-igGgb_xvzOXHSoy5M3nxt61ap/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913554/CollegeNotesApp/sem1/biology/bp3iwh7ppr15nbhtsunn.png",
                description: "Photosynthesis is the vital biological process by which green plants, algae, and some bacteria convert light energy from the sun into chemical energy stored in glucose and oxygen.",
              },
            ],
          },
         
        ],
      },
      //Mathematics
      {
        subjectName: "Mathematics",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Matrices ",
                documentUrl:
                  "https://drive.google.com/file/d/1hml0QulTHUygLZ8elfxAbToL0r1Rq52K/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913726/CollegeNotesApp/sem1/math/vv666jdhfobghfuajamy.jpg",
                description: "Matrices are mathematical structures used to organize and manipulate data in various fields, such as linear algebra, physics, computer graphics, and more. They consist of rows and columns of numbers or symbols arranged in a rectangular grid",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Calculus",
                documentUrl:
                  "https://drive.google.com/file/d/1iCD1upBEgMUH1F08oBqdudwWSEFZiC46/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913748/CollegeNotesApp/sem1/math/gjbyxvegobs9awb2tjyk.jpg",
                description: "Calculus is a branch of mathematics that deals with the study of rates of change and accumulation of quantities. It includes differential calculus, which focuses on derivatives and slopes of functions, and integral calculus, which deals with integrals and accumulation of quantities. ",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Partial Derivative",
                documentUrl:
                  "https://drive.google.com/file/d/1i4m4QtS65NkTCmpXfFmsvN-b-kkVvzF1/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913773/CollegeNotesApp/sem1/math/g0p6ay2jxlv5fjfhytdx.jpg",
                description: "Partial derivatives are a concept in calculus used to calculate the rate of change of a function concerning one of its several variables, holding the others constant. In other words, they measure how a function changes ",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "Gamma & Beta function",
                documentUrl:
                  "https://drive.google.com/file/d/1hpqEcuFzgHzzJEw_rAuQiLuWONTwnlUM/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913813/CollegeNotesApp/sem1/math/t2vghbpnjiztsvzxadaa.jpg",
                description: "Gamma Function (Γ(x)): The gamma function is an extension of the factorial function to complex and real numbers, except for non-positive integers where it's undefined.",
              },
            ],
          },
          {
            chapterName: "Chapter 5",
            documents: [
              {
                documentName: "Assignment",
                documentUrl:
                  "https://drive.google.com/file/d/1iEfONcPEpo2_2_sSThXRks2QPSkuIEEP/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695913904/CollegeNotesApp/sem1/math/zuylxvu4x0ifrtdtxwj3.png",
                description: "An assignment refers to a task or project given to an individual or a group with specific instructions and objectives to complete within a set timeframe. Assignments are commonly used in educational settings, such as schools and universities,",
              },
            ],
          },
        ],
      },
      //English
      {
        subjectName: "English",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Vocabulary Building",
                documentUrl:
                  "https://drive.google.com/file/d/1h0XwIidw_YTHb3KP5PuyfzL_HL8y7ofF/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695914036/CollegeNotesApp/sem1/ENGLISH/uby64snga2pdzigo1ple.jpg",
                description: "You can provide the description of your notes",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Prefixes and Suffixes",
                documentUrl:
                  "https://drive.google.com/file/d/1gvadH8FgJBcVNEBECJWu00DfjtlZolBv/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695914092/CollegeNotesApp/sem1/ENGLISH/cv8aohs1yp6lg2q4ghh8.jpg",
                description: "Prefixes are added to the beginning of a word and often change its meaning or create a new And Suffixes, on the other hand, are attached to the end of a word and can change its grammatical form or meaning. ",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Writting skills",
                documentUrl:
                  "https://drive.google.com/file/d/1gzYKGnk5SmF0EVD0zAJ8Wdk6aPkukIba/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695914149/CollegeNotesApp/sem1/ENGLISH/nudx70cjea1bdlopfafc.png",
                description: " They empower individuals to express their thoughts, ideas, and emotions clearly and persuasively. Good writing is not only about grammar and vocabulary but also about structuring content logically. Strong writing skills are invaluable in academia, business, and creative endeavors.",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "Language Skills",
                documentUrl:
                  "https://drive.google.com/file/d/1gvadH8FgJBcVNEBECJWu00DfjtlZolBv/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695914188/CollegeNotesApp/sem1/ENGLISH/j7jqu3gogkdgazwynkzk.png",
                description: "Language skills are essential in today's globalized world. They enable effective communication and foster cultural understanding. Proficiency in multiple languages can open doors to various opportunities, both personally and professionally. ",
              },
            ],
          },
         
        ],
      },
        //Environment Science(E.V.S)
      {
        subjectName: "Environment Science",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Environmental Science",
                documentUrl:
                  "https://drive.google.com/file/d/1161bOEQ3htgVRkMv_IPwi5uZDVq5j42j/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695914615/CollegeNotesApp/sem1/EVS/ijs8cmjqln4uvcsdazlc.jpg",
                description: "Environmental scientists investigate topics such as climate change, biodiversity loss, pollution, conservation, and sustainable resource management. They collect data, conduct experiments, and analyze environmental trends to develop solutions for pressing global challenges.",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Nature and Ecosystem",
                documentUrl:
                  "https://drive.google.com/file/d/1oPavyz9lumlD5ZEGLQcS5YLRmR-D5ZbF/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695914672/CollegeNotesApp/sem1/EVS/ixkortsv8eoalkyr2afe.jpg",
                description: " It encompasses everything from plants, animals, and microorganisms to mountains, rivers, and oceans. Nature is a complex and interconnected system that has evolved over billions of years and provides the foundation for life on our planet.",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Natural Resources",
                documentUrl:
                  "https://drive.google.com/file/d/1olny32rjljnzulP3WLfW8OiTCTqpz2NF/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695914706/CollegeNotesApp/sem1/EVS/du98iibyylknueefapvx.webp",
                description: " It encompasses everything from plants, animals, and microorganisms to mountains, rivers, and oceans. Nature is a complex and interconnected system that has evolved over billions of years and provides the foundation for life on our planet.",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "Environmental Pollution",
                documentUrl:
                  "https://drive.google.com/file/d/1olVhM9csPwxerHXWBXu19NTXL4vCfZrl/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695914759/CollegeNotesApp/sem1/EVS/qzkkalvynf6xhw23cyyb.jpg",
                description: "Environmental pollution refers to the contamination or degradation of the natural environment by harmful substances or activities, resulting in adverse effects on living organisms and ecosystems. It is a pressing global issue",
              },
            ],
          },
          
        ],
      },
        //Manufacturing Process
      {
        subjectName: "Manufacturing Process",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Manufacturing Process",
                documentUrl:
                  "https://drive.google.com/file/d/1mHpihRYrKx3aSAMlIH6AMDT_TL6G6bk3/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695915273/CollegeNotesApp/sem1/mp/fv48ddiuyrdmsbuhb6qf.jpg",
                description: "Efficient and well-managed manufacturing processes are critical for producing high-quality products, minimizing waste, and staying competitive in the market. Advances in technology, automation, and data analysis have led to significant improvements in manufacturing efficiency and product innovation.",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Pattern and Allowances",
                documentUrl:
                  "https://drive.google.com/file/d/1mM5y-BAm5cMKhHW68Zrg4KMxqDI2UFJq/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695952804/CollegeNotesApp/sem1/mp/kwzibjmek8m0nb81mep6.jpg",
                description: "pattern and allowances refer to the process of creating a pattern or template that includes allowances or tolerances to account for variations during the manufacturing process. These allowances ensure that the final product meets the required specifications and dimensions, ",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Plant Layout",
                documentUrl:
                  "https://drive.google.com/file/d/1mSwgtq6R9jpRKa7VJKo8zekqXKJLnld6/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695952829/CollegeNotesApp/sem1/mp/iiullx9g7kml7fczgyol.jpg",
                description: "Plant layout involves the arrangement of machines, equipment, workspaces, and facilities within a manufacturing or industrial plant. It is a crucial aspect of efficient operations and workflow. A well-designed plant layout aims to optimize the use of space, streamline production processes",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "Welding",
                documentUrl:
                  "https://drive.google.com/file/d/1mYSrOU-KLfikNwBFmhhkL-rsCXTZ6MXh/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695952989/CollegeNotesApp/sem1/mp/fjikqyqywetkml8yytow.jpg",
                description: "Welding is a fabrication process used to join materials, typically metals or thermoplastics, by melting them together and then allowing them to cool and fuse. This process is widely used in various industries, including construction, automotive, aerospace, and manufacturing. Welding techniques can vary,",
              },
            ],
          },
          
        ],
      },
    ],
  },
    //Semester 2
  {
    id: 2,
    name: "Semester 2",
    subjects: [
        //physics
      {
        subjectName: "Physics",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Electromagnetic Theory /Book",
                documentUrl:
                  "https://drive.google.com/file/d/106rF3JUmn46KeVjBbpw199my_XeHSobI/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953110/CollegeNotesApp/sem2/physics/xwwcvsfwbrtqwjjm2oha.jpg",
                description: "Electromagnetic Theory is a branch of physics that studies the fundamental principles governing the behavior of electric and magnetic fields. It explores how these fields interact and propagate through space. ",
              },
            ],
          },
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Electromagnetic Waves /Handwritten",
                documentUrl:
                  "https://drive.google.com/file/d/10Tr8vAjOJpWPjdLwloOwosokxCMtFwO1/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953165/CollegeNotesApp/sem2/physics/fylvgg6xngmd0erkt5xz.jpg",
                description: "Electromagnetic waves travel at the speed of light in a vacuum, which is approximately 299,792,458 meters per second (or about 186,282 miles per second). This universal speed limit has significant implications for our understanding of the cosmos.",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Introduction to Classical Mechanics",
                documentUrl:
                  "https://drive.google.com/file/d/10S77GBjTnQUZF3nEcuFvAJowq17V_6TL/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953141/CollegeNotesApp/sem2/physics/r2e6xa8ojgsqhwhsm6nq.png",
                description: "Classical mechanics is a branch of physics that deals with the motion of objects under the influence of forces. It's based on the principles of Newton's laws of motion and encompasses the study of kinematics ",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Dual nature of particles /Book",
                documentUrl:
                  "https://drive.google.com/file/d/10R2XBIGwjTxA5a8EC0-43-Bue31yHSWm/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953216/CollegeNotesApp/sem2/physics/fx1d7recjrfs65uveguy.jpg",
                description: "The dual nature of particles, often referred to as wave-particle duality, is a fundamental concept in quantum mechanics. It suggests that particles like electrons and photons exhibit both particle-like and wave-like properties. ",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Dual nature of particles /Handwritten",
                documentUrl:
                  "https://drive.google.com/file/d/10mfTP_f9yWy_2mqC9hBYnPpPxW_hjUIx/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953216/CollegeNotesApp/sem2/physics/fx1d7recjrfs65uveguy.jpg",
                description: "The dual nature of particles, often referred to as wave-particle duality, is a fundamental concept in quantum mechanics. It suggests that particles like electrons and photons exhibit both particle-like and wave-like properties. ",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "Free Electron Theory of Metals",
                documentUrl:
                  "https://drive.google.com/file/d/10G5JH5l_Hc_Uf9hvPHEiRL3Lcos7-9kn/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953261/CollegeNotesApp/sem2/physics/axyxdzj4ydjolyrtumpk.jpg",
                description: "The Free Electron Theory of Metals is a theoretical framework in solid-state physics that describes the behavior of electrons in a metal lattice. According to this theory, electrons in a metal are considered as nearly",
              },
            ],
          },
          
        ],
      },
        //Applied Mechanics
      {
        subjectName: "Applied Mechanics",
        chapters: [
          {
            chapterName: "Chapter 1 ",
            documents: [
              {
                documentName: "Introduction to Eng. Mechanics /Handwritten",
                documentUrl:
                  "https://drive.google.com/file/d/1-tSuauWQ5pg5EuMMSCsq83N1r7IJ9l-d/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953450/CollegeNotesApp/sem2/AM/njysxqndcigurozefgim.png",
                description: "Engineering Mechanics is a fundamental branch of engineering that deals with the study of forces, motion, and the behavior of physical bodies under the influence of various external forces. ",
              },
            ],
          },
          {
            chapterName: "Chapter 1 ",
            documents: [
              {
                documentName: "Introduction to Engineering Mechanics /Book Notes",
                documentUrl:
                  "https://drive.google.com/file/d/108l_rhmtGLQVP_XxZvAF58yWlUyYAwfh/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953450/CollegeNotesApp/sem2/AM/njysxqndcigurozefgim.png",
                description: "Engineering Mechanics is a fundamental branch of engineering that deals with the study of forces, motion, and the behavior of physical bodies under the influence of various external forces. ",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Centre of gravity / centroid",
                documentUrl:
                  "https://drive.google.com/file/d/1-JUW1JAUSqXPwkYvJsmpX2r2xssTO75B/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953586/CollegeNotesApp/sem2/AM/qwicafnfqjcdzmyoo2kk.jpg",
                description: "The center of gravity (or centroid) of an object is a point within the object where the entire weight of the object can be considered to act. It is a crucial concept in physics and engineering, particularly in the study of statics and mechanics.",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Friction",
                documentUrl:
                  "https://drive.google.com/file/d/1-fVpB9atA5A0v0x8q_YUQQeMeeDoxkyc/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953502/CollegeNotesApp/sem2/AM/j6rc8j5c2fyndnhtcwch.jpg",
                description: "Friction is a force that opposes the relative motion or tendency of such motion between two surfaces in contact. It arises due to microscopic irregularities on the surfaces and plays a significant role in everyday life, ",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "Power Transmission",
                documentUrl:
                  "https://drive.google.com/file/d/1-bD3nTI2BHE5qo-UP8JGq48E57JSkHjk/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953555/CollegeNotesApp/sem2/AM/bupd0gkisiwvmvhmgkag.webp",
                description: "Power transmission refers to the process of transferring mechanical energy or power from one location or component to another within a system or machine. This transfer of power is essential in various industries and applications",
              },
            ],
          },
          
        ],
      },
      //BEE
      {
        subjectName: "BEE",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "DC Circuits :Electrical Circuit ?Handwritten",
                documentUrl:
                  "https://drive.google.com/file/d/1-6pDJzaY9yt5yfQjHbktKiKYokckKfL8/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953752/CollegeNotesApp/sem2/BEE/egrchmywb3paefh6fo5q.jpg",
                description: "DC circuits, short for Direct Current circuits, are electrical circuits where the electric current flows in one direction continuously. They are fundamental components of electrical engineering and electronics.",
              },
            ],
          },
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "DC Circuits :Electrical Circuit elements /Book ",
                documentUrl:
                  "https://drive.google.com/file/d/110oapOXoXU9sSODycOvDREVidMj7TdRo/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953752/CollegeNotesApp/sem2/BEE/egrchmywb3paefh6fo5q.jpg",
                description: "DC circuits, short for Direct Current circuits, are electrical circuits where the electric current flows in one direction continuously. They are fundamental components of electrical engineering and electronics.",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Transformer",
                documentUrl:
                  "https://drive.google.com/file/d/1-HmI-XRVv90sOXcs0Uw7IdHdc637FSTb/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953815/CollegeNotesApp/sem2/BEE/gcoyi63n3w7jyr0ktkhd.jpg",
                description: "A transformer is an electrical device used to transfer electrical energy between two or more circuits through electromagnetic induction. It consists of two coils of wire, known as the primary and secondary windings,",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "SemiConductor Devices",
                documentUrl:
                  "https://drive.google.com/file/d/1nEnQfarXgwYWifVdglhVKPHQdA-3cIs-/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953843/CollegeNotesApp/sem2/BEE/hjvqwn1whedyf8osl2li.jpg",
                description: "A semiconductor device is an electronic component made from semiconductor materials, typically silicon, that can be used to control and manipulate electrical currents. These devices have electrical conductivity characteristics between those of conductors",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "Electrical Installation",
                documentUrl:
                  "https://drive.google.com/file/d/1-31LptInVxIITYJBNGbMg6cxVELrlu7D/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953895/CollegeNotesApp/sem2/BEE/o1zdxlc53jdw3qjw8xz8.jpg",
                description: "Electrical installation refers to the process of setting up electrical systems and components in a building, facility, or structure to ensure the safe and efficient distribution of electrical power.",
              },
            ],
          },
          {
            chapterName: "Chapter 5",
            documents: [
              {
                documentName: "Network Analysis",
                documentUrl:
                  "https://drive.google.com/file/d/110oapOXoXU9sSODycOvDREVidMj7TdRo/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695953873/CollegeNotesApp/sem2/BEE/zhrj8ys9wm2asjjvvznr.png",
                description: "Network analysis is a process used in various fields, including engineering, computer science, and social sciences, to study and understand the relationships and interactions among interconnected elements or entities",
              },
            ],
          },
        ],
      },
    ],
  },
    //Semester 3
  {
    id: 3,
    name: "Semester 3",
    subjects: [
        //Software Enginnering
      {
        subjectName: "Software Engineering",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Software Eng. Testing tools",
                documentUrl:
                  "https://drive.google.com/file/d/1rApzZAVx_6yrOJ1HY8ZyH0DOW8Ln9Djs/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695954232/CollegeNotesApp/sem3/software%20eng/d3d9jdygxrcqqcl6abfn.jpg",
                description: "Software engineering and testing tools, such as IDEs like Visual Studio and version control systems like Git, are essential for efficient software development. These tools streamline coding, collaboration, and version management, ensuring high-quality software products.",
              },
            ],
          },
        ],
      },
        //BI
      {
        subjectName: "Business Intelligence",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "",
                documentUrl:
                  "https://drive.google.com/file/d/1qvXgKLus18JcYfmsRqp20-odSwIBZK68/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695954180/CollegeNotesApp/sem3/Bi/jpptibvnnhhnaq8f33np.jpg",
                description: "Business Intelligence (BI) refers to the technologies, processes, and strategies used to collect, analyze, and present business data to support decision-making. BI tools and software help organizations transform raw data into meaningful insights, reports, and visualizations.",
              },
            ],
          },
          
        ],
      },
      //DSA
      {
        subjectName: "DSA",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Fundamentals of DSA",
                documentUrl:
                  "https://drive.google.com/file/d/1SaKycOTamK6Cvbfje7cSyuZu5RFnEp43/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695954455/CollegeNotesApp/sem3/DSA/kcutzy96aya6ipgxi00s.jpg",
                description: "Data Structures and Algorithms (DSA) are foundational concepts in computer science. DSA involves organizing and processing data efficiently using structures like arrays and algorithms, which are step-by-step problem-solving methods. ",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Stack and Queue",
                documentUrl:
                  "https://drive.google.com/file/d/1qXbh_H6EWvXz0nvGMmrpFcY9-IzM1xYB/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695954510/CollegeNotesApp/sem3/DSA/fitplyo3k9mtl2ldu9kf.png",
                description: "A stack is a linear data structure that follows the Last-In-First-Out (LIFO) principle. It means that the last element added to the stack is the first one to be removed. Stacks are used for tasks like function call management, undo mechanisms, and expression evaluation.",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "LinkedList and Trees",
                documentUrl:
                  "https://drive.google.com/file/d/1qjPJx1k-z0vGUKo8T44NeUMu84WJjRFr/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695954515/CollegeNotesApp/sem3/DSA/tzflnecwayrvfhigaobp.jpg",
                description: "A LinkedList is a linear data structure in which elements are connected using pointers. Each element in a LinkedList is called node and consists of two parts: data and a reference (or link) to the next node in the sequence",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "All Notes",
                documentUrl:
                  "https://drive.google.com/file/d/1qtr6if7qBOhBq57YL0CCAfEgCYqsgARJ/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695954731/CollegeNotesApp/sem3/DSA/cyy4seolvl9aib5cbkry.png",
                description: "Trees are hierarchical data structures composed of nodes. Each tree has a root node, and each node can have zero or more child nodes, forming a branching structure. Trees are used for various purposes, including organizing data efficiently (e.g., Binary Search Trees for sorting and searching),",
              },
            ],
          },
          
        ],
      },
    ],
  },
    //Semester 4
  {
    id: 4,
    name: "Semester 4",
    subjects: [
        //Operating system
      {
        subjectName: "Operating System",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Basic on Operating System",
                documentUrl:
                  "https://drive.google.com/file/d/114EYBXf4WbM95GabEeTe9LzXKgtEhwEk/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695955110/CollegeNotesApp/sem4/OS/hakxdvca36rmsgac1slm.jpg",
                description: "An Operating System (OS) is software that manages computer hardware and provides a user-friendly interface for running applications. It handles tasks like process management, memory allocation, file management, and device control.",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Operating System",
                documentUrl:
                  "https://drive.google.com/file/d/10vXJFNFkXNViUmasQfv43OwvrVFb-JWz/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695955115/CollegeNotesApp/sem4/OS/mjevzgae8rakhg76hil2.svg",
                description: "You can provide the description of your notes",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Memory Management",
                documentUrl:
                  "https://drive.google.com/file/d/11-rsAiHgFw85AAJkwIsu4xZaZqaXDUrH/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695955104/CollegeNotesApp/sem4/OS/ud94qatikq5ly3rswtn5.png",
                description: "The OS ensures efficient use of resources and enables users to interact with the computer. Examples include Windows, macOS, and Linux.",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "C.P.U Scheduling",
                documentUrl:
                  "https://drive.google.com/file/d/116SY1jocAYf3QO4m5A97ZdddDUDRpnjL/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695955101/CollegeNotesApp/sem4/OS/wkb3rhdmoxgr7jihpfvf.png",
                description: "CPU scheduling is a critical component of an operating system that manages the execution of multiple processes on a CPU. It involves selecting a process from the ready queue and allocating the CPU to that process. ",
              },
            ],
          },
          {
            chapterName: "Extra-Notes",
            documents: [
              {
                documentName: "Types of Operating System",
                documentUrl:
                  "https://docs.google.com/document/d/11p7NNwxoI6J5YsUkIQYJ1x3E01TwF4Ka/edit?usp=drive_link&ouid=105809072557886058588&rtpof=true&sd=true",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695955110/CollegeNotesApp/sem4/OS/hakxdvca36rmsgac1slm.jpg",
                description: "algorithms determine the order in which processes run, optimizing factors like CPU utilization, throughput, response time, and fairness. Common scheduling algorithms include First-Come-First-Serve (FCFS), Shortest Job First (SJF), Round Robin (RR), and Priority Scheduling, each with its own characteristics and trade-offs.",
              },
            ],
          },
          {
            chapterName: "Extra-Notes",
            documents: [
              {
                documentName: "Threads",
                documentUrl:
                  "https://docs.google.com/document/d/1oEwPAyVmTGze_WICgpcLUFiYKYDWodmw/edit?usp=drive_link&ouid=105809072557886058588&rtpof=true&sd=true",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695955221/CollegeNotesApp/sem4/OS/ws8kzdqkclylwdglvywp.jpg",
                description: "Threads are lightweight, independent units of execution within a process. They share the same memory space as the parent process and can run concurrently. Threads allow for efficient multitasking and can perform tasks in parallel.",
              },
            ],
          },
          {
            chapterName: "Extra-Notes",
            documents: [
              {
                documentName: "Semaphore",
                documentUrl:
                  "https://docs.google.com/presentation/d/12Bd2I5yC_MDgz25r8uuB5M_tQDnLv65p/edit?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695955250/CollegeNotesApp/sem4/OS/bw256icw6ooguudyao32.png",
                description: "A semaphore is a synchronization primitive used in operating systems and concurrent programming to control access to shared resources. It acts as a counter that helps manage the number of threads that can simultaneously access a resource.",
              },
            ],
          },
          {
            chapterName: "Extra-Notes",
            documents: [
              {
                documentName: "Reader and Writer",
                documentUrl:
                  "https://docs.google.com/presentation/d/12BYlqvBo9in5iuJBaTD-f9BxMg035CcM/edit?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695955734/CollegeNotesApp/sem4/OS/jtvxsj0kivsqojzzwvtg.jpg",
                description: "The challenge is to design synchronization mechanisms (e.g., using semaphores or locks) that allow for concurrent reading by multiple readers while ensuring exclusive access for writing.",
              },
            ],
          },
          {
            chapterName: "Extra-Notes",
            documents: [
              {
                documentName: "Process Synchronization",
                documentUrl:
                  "https://docs.google.com/presentation/d/125uu4KgNDYkXCIFhU4vCOEuhjwS0s8t2/edit?usp=drive_link&ouid=105809072557886058588&rtpof=true&sd=true",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695955278/CollegeNotesApp/sem4/OS/dnuqunpla0paq0b55ykb.jpg",
                description: "Process synchronization is fundamental for building reliable and concurrent software systems, ensuring that shared resources are accessed safely and that processes or threads work together coherently.",
              },
            ],
          },
          {
            chapterName: "Extra-Notes",
            documents: [
              {
                documentName: "Process ",
                documentUrl:
                  "https://docs.google.com/document/d/11bdZOHyxhNzS4cs72-MqlWAdmgZtTR5z/edit?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695955902/CollegeNotesApp/sem4/OS/sahoyfsuiuckwpk9m0jy.png",
                description: "In computing, a process refers to an independent program or task that is running on a computer's operating system. Each process has its own memory space and system resources, making it self-contained and isolated from other processes.",
              },
            ],
          },
          {
            chapterName: "Extra-Notes",
            documents: [
              {
                documentName: "OS Architecture ",
                documentUrl:
                  "https://docs.google.com/presentation/d/11S6y3Mqlq0YuhB6LtaQ_OqnzUUSiPN5F/edit?usp=drive_link&ouid=105809072557886058588&rtpof=true&sd=true",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695956140/CollegeNotesApp/sem4/OS/qybkw2dnjpr1q5syltps.png",
                description: "Operating System (OS) architecture is the structure that defines how an OS is organized. It typically includes layers such as the kernel (core component), hardware abstraction layer (HAL), system services, user interface, and applications.",
              },
            ],
          },
          {
            chapterName: "Extra-Notes",
            documents: [
              {
                documentName: "Multi Processor",
                documentUrl:
                  "https://docs.google.com/presentation/d/11S6y3Mqlq0YuhB6LtaQ_OqnzUUSiPN5F/edit?usp=drive_link&ouid=105809072557886058588&rtpof=true&sd=true",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695955979/CollegeNotesApp/sem4/OS/fvjxnga5zm01vaokacpq.jpg",
                description: "A multi-processor system is a computer system that contains more than one central processing unit (CPU). These CPUs work in parallel to execute tasks and processes simultaneously, improving overall system performance and multitasking capabilities. ",
              },
            ],
          },
          {
            chapterName: "Extra-Notes",
            documents: [
              {
                documentName: "DeadLock and avoidance",
                documentUrl:
                  "https://docs.google.com/presentation/d/1HUzSBHNiJwM7VFkDPS-eCH7W2T7vatJo/edit?usp=drive_link&ouid=105809072557886058588&rtpof=true&sd=true",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695956005/CollegeNotesApp/sem4/OS/v4kfhyogozlha2wznkr1.jpg",
                description: "Deadlock is a situation in computer science where two or more processes are unable to proceed because they are each waiting for the other to release a resource. Deadlock avoidance is a strategy used in operating systems to prevent such situations",
              },
            ],
          },
          {
            chapterName: "Extra-Notes",
            documents: [
              {
                documentName: "OS Function",
                documentUrl:
                  "https://drive.google.com/file/d/1LjLkgGPSr9L8HMkR84d-u3dGO27dclnG/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695956393/CollegeNotesApp/sem4/OS/sekume2iw7rmt0am9kt0.png",
                description: "Operating systems (OS) perform several crucial functions, including process management, memory management, file system management, device management, security and access control, user interface, networking, error handling, task scheduling, and interrupt handling. ",
              },
            ],
          },
        ],
      },
        //DBMS
      {
        subjectName: "DBMS",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Database System Architecture",
                documentUrl:
                  "https://drive.google.com/file/d/10MSY5pMCqVTvoS3JiVXWabAiC4V28sNA/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695956541/CollegeNotesApp/sem4/dbms/mmarbr4nygrdjgs2w1mk.jpg",
                description: "This is the front-end part of the database system that interacts with users or applications. It includes forms, queries, reports, and other user-friendly interfaces.",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Relational Query Languages",
                documentUrl:
                  "https://drive.google.com/file/d/10QPPfizlO9Q5uy8Q03pxEnM5PHGetdFl/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695956571/CollegeNotesApp/sem4/dbms/sr9kwh4jlp3tbmygphra.jpg",
                description: "Relational Query Language (RQL) is a domain-specific language used for querying relational databases. It allows users to retrieve, manipulate, and manage data stored in a relational database management system (RDBMS). ",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Storage devices",
                documentUrl:
                  "https://drive.google.com/file/d/10gJcw2suFHtNFhsu53n39Osc8Kij_4Vg/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695956611/CollegeNotesApp/sem4/dbms/ne2ueptsmzrhpllt0mqp.png",
                description: "we can store our data in devices like HDD ,SDD and others.they are portable and easy to store for future management and utilization",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "Database Security",
                documentUrl:
                  "https://drive.google.com/file/d/10NJETbBYLlXy92b1WkPqIsXtgzpDndqS/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695956630/CollegeNotesApp/sem4/dbms/ni4ywaeemejc9nbic20b.jpg",
                description: "Data security refers to the practice of protecting digital information from unauthorized access, corruption, or theft. It encompasses various strategies, technologies, and processes aimed at safeguarding data throughout its lifecycle",
              },
            ],
          },
          {
            chapterName: "Miscellaneous",
            documents: [
              {
                documentName: "DBMS",
                documentUrl:
                  "https://drive.google.com/drive/u/1/folders/1FiK9xVQdKHd2G5cIdKkgbWMe6wixUmN3",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695957018/CollegeNotesApp/sem4/dbms/y5bdhrklxcqfznjvc2ci.jpg",
                description: "DBMSs are essential components in modern information systems, supporting a wide range of applications, from business databases to data warehouses and big data platforms",
              },
            ],
          },
          {
            chapterName: "Lab files",
            documents: [
              {
                documentName: "file Notes 1",
                documentUrl:
                  "https://drive.google.com/file/d/10ToOV_8ti-cSq5lCVRoPxw9qcWz-CoY8/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695957052/CollegeNotesApp/sem4/dbms/qx97tq2f9ugsmfyggclx.jpg",
                description: " Users can define the structure of their data using data models, such as the Entity-Relationship Model (ER Model), to design databases.",
              },
            ],
          },
          {
            chapterName: "Lab files",
            documents: [
              {
                documentName: "file Notes 2",
                documentUrl:
                  "https://drive.google.com/file/d/10WkrlzhHQQSX_cslLJLHd9wfuX4IjnXQ/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695956679/CollegeNotesApp/sem4/dbms/roulhnopf582lkjgjlph.png",
                description: "DBMSs are essential components in modern information systems, supporting a wide range of applications, from business databases to data warehouses and big data platforms",
              },
            ],
          },
        ],
      },
      //DAA
      {
        subjectName: "DAA",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Algorithm",
                documentUrl:
                  "https://drive.google.com/file/d/1-Cfxx5jd3GWepDxZ9NKSkAlN8f0wJWxA/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695957228/CollegeNotesApp/sem4/DAA/b8zuvp4tdrnbfg1mttzg.jpg",
                description: "Algorithms are designed to be efficient, reliable, and optimized for specific objectives, such as sorting data, searching for information, or solving complex computational problems.",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Advanced Data Structures",
                documentUrl:
                  "https://drive.google.com/file/d/1zrHH2dtXZEkc7VhQCOylnFzD2C3pdm7D/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695957419/CollegeNotesApp/sem4/DAA/yazfitzdh0adbvh1bb0o.jpg",
                description: "Advanced data structures refer to specialized data structures that are designed to address specific computational problems efficiently. These structures go beyond the basic data structures like arrays, lists, and trees, offering more advanced capabilities and optimizations. ",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Divide conquer and Greedy",
                documentUrl:
                  "https://drive.google.com/file/d/1nJWhn0CpYLyRvTHuNjAHH6UO5Uaec7FR/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695957257/CollegeNotesApp/sem4/DAA/g1hhsodbjnbemqqwlvys.jpg",
                description: "Divide and conquer is a well-known algorithmic paradigm used in computer science and mathematics to solve complex problems by breaking them down into simpler subproblems, solving these subproblems independently, ",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "Topological Sort",
                documentUrl:
                  "https://drive.google.com/file/d/1zqImRjI1wjF63OXx5zJQPRZhaxN8Ixuc/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695957274/CollegeNotesApp/sem4/DAA/zisflpiml2bo8lppd7gq.jpg",
                description: "Topological sorting is an algorithmic technique used to linearly order the vertices of a directed acyclic graph (DAG) in such a way that for every directed edge (u, v), vertex u comes before vertex v in the ordering",
              },
            ],
          },
          {
            chapterName: "Miscellaneous",
            documents: [
              {
                documentName: "Combined notes All",
                documentUrl:
                  "https://drive.google.com/file/d/1-3vrnuSlT_Ol9HPvSBcddYwyEFefTZgM/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695957401/CollegeNotesApp/sem4/DAA/dcijccf3obgngzheatjz.jpg",
                description: "These advanced data structures are essential tools for algorithm design and optimization, and they play a crucial role in solving complex computational problems efficiently.",
              },
            ],
          },
        ],
      },
      //CN
      {
        subjectName: "CN",
        chapters: [
          {
            chapterName: "Chapter 1",
            documents: [
              {
                documentName: "Intro Computer Networking",
                documentUrl:
                  "https://drive.google.com/file/d/1tpSdULSGt6RDa0za_tpcP5uy_tIdP7v0/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695958056/CollegeNotesApp/sem4/CN/vjaucg6xmeaf2ydcrahn.jpg",
                description: "Computer networks are interconnected systems of computers and other devices that communicate and share resources with each other",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Data link Layer",
                documentUrl:
                  "https://drive.google.com/file/d/1tsy4wv3Hvok-kPQsffHKR8DxPvO6hfek/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695957938/CollegeNotesApp/sem4/CN/xxsf0sidt1gp0ntqdwkn.jpg",
                description: "The data link layer is the second layer in the OSI (Open Systems Interconnection) model and the TCP/IP protocol suite. Its primary function is to ensure reliable data communication between devices on the same physical network segment.",
              },
            ],
          },
          {
            chapterName: "Chapter 2",
            documents: [
              {
                documentName: "Data link Layer Handwritten",
                documentUrl:
                  "https://drive.google.com/file/d/1tqVu2oLEsIbQAGRGWyzqFlDEaW5Sbf5u/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695957951/CollegeNotesApp/sem4/CN/gauxdhx4kmgdji49tmo7.png",
                description: "The data link layer is the second layer in the OSI (Open Systems Interconnection) model and the TCP/IP protocol suite. Its primary function is to ensure reliable data communication between devices on the same physical network segment.",
              },
            ],
          },
          {
            chapterName: "Chapter 3",
            documents: [
              {
                documentName: "Switching",
                documentUrl:
                  "https://drive.google.com/file/d/1ttpaMtDpGFXwynCQJKwVE7yip_rbMLNo/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695957970/CollegeNotesApp/sem4/CN/qwnnfibnfcsduvwdlzrw.jpg",
                description: "Switching is a fundamental concept in computer networking that refers to the process of forwarding data packets from one device to another within a network. It plays a crucial role in ensuring efficient and reliable communication in modern networks.",
              },
            ],
          },
          {
            chapterName: "Chapter 4",
            documents: [
              {
                documentName: "Transport Layer",
                documentUrl:
                  "https://drive.google.com/file/d/1ytrGY6VBias6uuDiiYFMfg5L88dqAfYj/view?usp=drive_link",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695958100/CollegeNotesApp/sem4/CN/ldxcicim9wlbkis5holv.jpg",
                description: "The transport layer is a crucial part of the OSI model and the TCP/IP protocol suite in computer networking. It serves as the intermediary between the application layer and the network layer, providing communication services that ensure data is reliably and accurately ",
              },
            ],
          },
          {
            chapterName: "Chapter 5",
            documents: [
              {
                documentName: "Miscellaneous",
                documentUrl:
                  "https://docs.google.com/presentation/d/1q5utrndKQ9qJ6UWG4S6xzx1NbEV5ftVI/edit?usp=drive_link&ouid=105809072557886058588&rtpof=true&sd=true",
                thumbnail:
                  "https://res.cloudinary.com/dbd0psf0f/image/upload/v1695958041/CollegeNotesApp/sem4/CN/arj7dyhyajhvggl9b8tz.jpg",
                description: " It includes mechanisms for error detection and correction, such as checksums and sequence numbers, to guarantee the integrity and reliability of data transmission.",
              },
            ],
          },
        ],
      },
    ],
  },
  //Semester 5
  {
    id: 5,
    name: "Semester 5",
    subjects: [
        //Operating system
      {
        subjectName: "Coming soon",
        chapters: [
        ],
      },
     
    ],
  },
  //Semester 6
  {
    id: 6,
    name: "Semester 6",
    subjects: [
        //Operating system
      {
        subjectName: "Coming soon",
        chapters: [
        ],
      },
     
    ],
  },
  //Semester 7
  {
    id: 7,
    name: "Semester 7",
    subjects: [
        //Operating system
      {
        subjectName: "Coming soon",
        chapters: [
        ],
      },
     
    ],
  },
  //Semester 8
  {
    id: 8,
    name: "Semester 8",
    subjects: [
        //Operating system
      {
        subjectName: "Coming soon",
        chapters: [
        ],
      },
     
    ],
  },
];

export default semestersData;
